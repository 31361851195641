import { useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { ProcessTableSkeleton } from '@invisible/ui/skeleton'
import { createColumnHelper, Table } from '@invisible/ui/table'
import { PrismaAll } from '@invisible/ultron/prisma'
import { FC, useMemo } from 'react'

interface UseCasesTableProps {
  pageSize?: 10 | 25 | 50 | 75 | 100
}

type TUseCaseCategory = PrismaAll.UseCaseCategory & {
  UseCase: (PrismaAll.UseCase & {
    ProcessToUseCase: (PrismaAll.ProcessToUseCase & {
      process: PrismaAll.Process & {
        revenue?: number
      }
    })[]
    _count: {
      ProcessToUseCase: number
    }
  })[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const UseCaseCategoriesTable: FC<UseCasesTableProps> = ({ pageSize = 25 }) => {
  const { data: useCaseCategoryData, isLoading: isUseCaseCategoryDataLoading } = useQuery([
    'useCaseCategory.findAllWithRelations',
  ])

  const columnHelper = createColumnHelper<TUseCaseCategory>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'Name / ID',
        meta: { width: '20' },
        cell: (info) => (
          <Table.CellNameId
            name={info.row.original.name}
            id={info.row.original.id}
            href={`/usecases/${String(info.row.original.slug)}`}
            showCopy={false}
          />
        ),
      }),
      columnHelper.display({
        header: 'Status',
        meta: { width: '5' },
        cell: () => (
          <Table.Cell>
            <div className='flex items-center gap-2'>
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  background: '#4DBC25',
                  borderRadius: '10px',
                }}></div>
              Active
            </div>
          </Table.Cell>
        ),
      }),
      columnHelper.display({
        header: 'Companies',
        meta: { width: '20' },
        cell: (info) => {
          const companyIds: string[] = []
          info.row.original.UseCase.forEach((item) => {
            item.ProcessToUseCase.forEach((processItem) => {
              const companyId = processItem.process?.companyId
              if (companyId) {
                companyIds.push(companyId)
              }
            })
          })
          return <Table.Cell>{[...new Set(companyIds)].length}</Table.Cell>
        },
      }),
      columnHelper.display({
        header: 'Use Cases',
        meta: { width: '20' },
        cell: (info) => <Table.Cell>{info.row.original.UseCase.length}</Table.Cell>,
      }),
      columnHelper.display({
        header: 'Processes',
        meta: { width: '20' },
        cell: (info) => (
          <Table.Cell>
            {info.row.original.UseCase.reduce(
              (acc, useCase) => acc + useCase._count.ProcessToUseCase,
              0
            )}
          </Table.Cell>
        ),
      }),
      columnHelper.display({
        header: 'Success Stories',
        cell: () => <Table.Cell>0</Table.Cell>,
        meta: { width: '5' },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created At',
        cell: (info) => <Table.CellDate date={new Date(info.row.original.createdAt)} />,
        meta: {
          width: '5',
        },
      }),
      columnHelper.display({
        header: 'Revenue',
        cell: (info) => {
          const revenue = { total: 0 }
          info.row.original.UseCase.forEach((useCase) => {
            useCase.ProcessToUseCase.forEach((processItem) => {
              revenue.total += processItem.process?.revenue ?? 0
            })
          })
          return <Table.CellMoney type='millicents' amount={revenue.total} />
        },
        meta: { width: '5' },
      }),
    ],
    [columnHelper]
  )

  if (isUseCaseCategoryDataLoading) return <ProcessTableSkeleton />

  return (
    <div className='flex flex-col gap-y-4'>
      <div className='flex items-center justify-between'>
        <div className='flex flex-col gap-y-2'>
          <div className='text-header text-xl font-bold'>Use Case Categories</div>
          <div className='text-strong'>What are we doing?</div>
        </div>
        <Button variant='secondary' size='md' disabled>
          Nominate A New Category
        </Button>
      </div>
      <Table.PaginationProvider>
        <Table.Container>
          <Table
            data={useCaseCategoryData ?? []}
            columns={columns}
            isDataLoading={isUseCaseCategoryDataLoading}
            defaultPageSize={pageSize}
          />
          <Table.Pagination />
        </Table.Container>
      </Table.PaginationProvider>
    </div>
  )
}
